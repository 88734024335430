import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Input,
    InputLabel,
    Button,
    Modal,
    InputAdornment,
    IconButton,
    TextField,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import { Wrong, ValidIcon, InValidIcon } from "./assets";
import CommonComponent from "./CommonComponent.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// Customizable Area End

import EmailAccountLoginController, {
    Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End


export default class EmailAccountLoginBlock extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderPasswordValidationList() {
        const passwordValidationCriteria = [
          {
            condition: /[A-Z]/.test(this.state.password),
            text: 'At least one capital letter',
          },
          {
            condition: /[a-z]/.test(this.state.password),
            text: 'At least one lowercase letter',
          },
          {
            condition: /\d/.test(this.state.password),
            text: 'At least one number',
          },
          {
            condition: this.state.password?.length >= 8,
            text: 'Minimum character length is 8 characters',
          },
        ];
    
        return (
          <Box>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              {passwordValidationCriteria.map((criteria, index) => (
                <li key={index}>
                  {criteria.condition ? <img src={ValidIcon} alt="Valid" /> : <img src={InValidIcon} alt="Invalid" />} {criteria.text}
                </li>
              ))}
            </ul>
          </Box>
        );
      }
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                <Modal
                    open={this.state.open}
                    data-testId="handleClose1"
                    onClose={() => this.handleClose()} >
                    <Box className="webStyle">
                        <Box sx={{ display: "flex", justifyContent: "end" }}>
                            <img data-testId="handleClose" onClick={() => this.handleClose()} src={Wrong} />
                        </Box>
                        <Typography className="webStyle1">Thank you</Typography>
                        <Typography style={{ marginTop: "5%" }}>Please check your inbox to reset your password.</Typography>
                        <Typography> Follow the link provided to Login.</Typography>
                        <Box className="buttonroot11">
                            <Box style={{ display: "flex", width: "100%" }}>
                                <Button data-testId="handleResetEmail" style={{ background: "#1391AB", borderRadius: "8px", width: "100%", textTransform: "inherit", color: "white" }} onClick={() => { this.resetModalClose() }}>Check inbox</Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                
                <ThemeProvider theme={theme}>
                    <Box className="mainRoot">
                        <CommonComponent navigation={this.props.navigation} id={""} />
                        
                        {this.state.recoveryButton === false ? (
                            <Box className="rootbox">          
                                <Box className="boxRoot">
                              
                                    <Box className="welcomeBox">
                                        Forgot password
                                    </Box>
                                    {this.state.error && (
									<div className="error-popup" style={{ color: '#8D0707', backgroundColor: '#FEE2E2', borderLeft: 'solid 4px #DC2626', padding: '7px 10px 7px 12px' }}>
										{this.state.error}
									</div>
								)}
                                    <Box style={{ display: "flex", marginTop: "15px" }}>
                                        <InputLabel className="lableStyle">Email address</InputLabel>
                                    </Box>
                                    <Box className="inputemail">
                                        <Input placeholder="Enter your email" data-testId="setEmail" value={this.state.email} onChange={(e: any) => this.setEmail(e.target.value)} style={{ border: '1px solid #70b3b7', borderRadius: "4px", width: "99%", background: "white" }} type="text" />
                                    </Box>
                                    <Typography className="signupAccountStyle11">You will receive a link to your entered email</Typography>
                                    <Box className="buttonroot">
                                       
                                            <Button data-testId="handleRecovery" onClick={() => { this.handleRecovery() }} style={{ backgroundColor: "#1391AB", textTransform: "inherit", color: "white", width: "25vw", height: "56px", borderRadius: "8px", fontWeight: 700, gap: "8px", fontFamily: "Roboto Condensed" }}> Send recovery link</Button>
                                     
                                    </Box>
                                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Box data-testId="handleOpen1" onClick={() => this.handleRecovery()} className="signupAccountStyle1">Resend email</Box>
                                        <Box className="signupAccountStyle">Back to <Box style={{ color: "white" }}>/</Box><Link style={{ color: "#1391AB", textDecoration: "none" }} to="/EmailAccountLoginBlock">Login</Link> </Box>
                                    </Box>
                                </Box>
                            </Box>) : (
                            <Box className="rootbox">
                                <Box className="boxRoot">
                                    <Box className="welcomeBox">
                                        Reset password
                                    </Box>
                                    {this.state.error && (
                                    <div className="error-popup" style={{ color: '#8D0707', backgroundColor: '#FEE2E2', borderLeft: 'solid 4px #DC2626', padding: '7px 10px 7px 12px' }}>
                                        {this.state.error}
                                    </div>
                                )}
                                 {!this.state.passwordsMatch && (
                                    <Box className="error-popup" style={{ color: '#8D0707', backgroundColor: '#FEE2E2', borderLeft: 'solid 4px #DC2626', padding: '7px 10px 7px 12px' }}>
                                        <Typography className="error-text">Incorrect password</Typography>
                                    </Box>
                                )}
                                    <Box className="emailPAswordRoot">
                                        <InputLabel className="lableStyle">Password
                                        </InputLabel>
                                    </Box>
                                    <Box className="inputemail">
                                        <TextField data-testId="handleClickShowPasswordText"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            style={{ marginRight: "2px" }}
                                                            aria-label="toggle password visibility"
                                                            onClick={() => this.handleClickShowPassword()}
                                                            edge="end"
                                                        >
                                                            {this.state.enablePasswordField ? (
                                                                <Visibility style={{ color: "#94A3B8" }} />
                                                            ) : (
                                                                <VisibilityOff style={{ color: "#94A3B8" }} />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }} placeholder=" Enter your password" value={this.state.password} onChange={(e: any) => this.setPassword(e.target.value)} style={{ border: '1px solid #70b3b7', borderRadius: "4px", width: "99%", background: "white" }} type={this.state.enablePasswordField ? "text" : "password"} >

                                        </TextField>

                                    </Box>
                                    <Box className="emailPAswordRoot">
                                        <InputLabel className="lableStyle">Re-enter Password
                                        </InputLabel>
                                    </Box>
                                    <Box data-testId="" className="inputemail">
                                        <TextField InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        style={{ marginRight: "2px" }}
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowConfirmPassword}
                                                        edge="end"
                                                    >
                                                        {this.state.enableConfirmPasswordField ? (
                                                            <Visibility style={{ color: "#94A3B8" }} />
                                                        ) : (
                                                            <VisibilityOff style={{ color: "#94A3B8" }} />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} placeholder="Re-enter your password" data-testId="txtInputPassword1" 
                                        value={this.state.confirmPassword} onChange={(e: any) => this.setConfirmPassword(e.target.value)}
                                        type={this.state.enableConfirmPasswordField ? "text" : "password"}
                                        className={`psw-input ${!this.state.passwordsMatch ? 'password-mismatch' : ''}`}>

                                        </TextField>
                                    </Box>
                                    <Box>
                                         {this.renderPasswordValidationList()}
                                    </Box>
                                    <Box className="buttonroot">
                                        
                                            <Button data-testId="handleForgotPasswordLogin" style={{ backgroundColor: "#1391AB", textTransform: "inherit", color: "white", width: "25vw", height: "56px", borderRadius: "8px", fontWeight: 700, gap: "8px", fontFamily: "Roboto Condensed" }} onClick={() => { this.handleResetPassword("reset_password") }}>Login</Button>
                                      
                                    </Box>
                                    <Box>
                                        <Typography className="signupAccountStyle">Don’t have an account? <Link style={{ color: "#1391AB", textDecoration: "none", marginLeft: "2%" }} to="/SignUpPage/false">SignUp</Link></Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }

}
// Customizable Area Start
// Customizable Area End
