import React from "react";

// Customizable Area Start
import {
	Box,
	Button,
	Input,
	Typography,
	Checkbox,
	InputLabel,
	Modal,
	InputAdornment,
	IconButton,
	TextField,
	// Customizable Area Start
	// Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CommonComponent from "./CommonComponent.web";
import { Link } from "react-router-dom";
import { Wrong} from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SignupLoginTab from "./SignupLoginTab.web";
// Customizable Area End

import EmailAccountLoginController, {
	Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
	palette: {
		primary: {
			main: "#0000ff",
			contrastText: "#fff",
		},
	},
});
// Customizable Area End


export default class SignUpPage extends EmailAccountLoginController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	// Customizable Area Start
	async componentDidMount(): Promise<void> {
		const rememberMe = JSON.parse(localStorage?.getItem('checkedRememberMe')!);
		if (rememberMe) {
			this.setState({ checkedRememberMe: rememberMe });
		}

		const emailString = localStorage.getItem('email');

		if (emailString) {
			try {
				const userEmail = JSON.parse(emailString)?.email;

				if (userEmail) {
					this.setState({ email: userEmail });
				}
			} catch (error) {
				console.error('Error parsing email from localStorage:', error);
			}
		}
	}
	// Customizable Area End
	render() {

		return (
			// Customizable Area Start
			// Required for all blocks
			<>
				{
					this.state.checkInBox && (

						<Modal
							className="modalThanks"
							data-testId="modalSignup1"
							open={this.state.open}
							onClose={() => this.handleClose()} >

							<Box className="webStyle">
								<Box className="thanks-box">
									<img data-testId="handleClose" onClick={() => this.handleClose()} src={Wrong} />
								</Box>
								<Typography className="webStyle1">Thank you</Typography>
								<Typography className="Typography-1" style={{ marginTop: "5%" }}>{this.state.successMessage}</Typography>
								{/* <Typography> Follow the link provided to complete your Registration.</Typography> */}
								<Box className="buttonroot1">
									<Box className="Checkinbox" style={{}}>
										<Button data-testId="Checkinbox" onClick={() => { this.confirmemail() }}>Check inbox</Button>
									</Box>
								</Box>
							</Box>
						</Modal>
					)
				}
				<ThemeProvider theme={theme}>
					<Box className="mainRoot">
						<CommonComponent navigation={this.props.navigation} id={""} />
						<Box className="rootbox">
							<Box className="boxRoot">
								<Box className="welcomeBox">
									Welcome!
								</Box>
							<SignupLoginTab navigation={this.props.navigation} id={""} />
								{this.state.error && (
									<div className="error-popup" style={{ color: '#8D0707', backgroundColor: '#FEE2E2', borderLeft: 'solid 4px #DC2626', padding: '7px 10px 7px 12px' }}>
										{this.state.error}
									</div>
								)}
								{!this.state.confirmButtom && (
									<>
										<Box className="emailPAswordRoot">
											<InputLabel className="lableStyle">Email address</InputLabel>
										</Box>
										<Box className="inputemail">
											<Input placeholder=" Enter your email" data-testId="setEmail" value={this.state.email} onChange={this.handleEmailChange} className="emailinput" type="text" />
										</Box>
										<Box className="checkbox checkBoxRoot">
											<Checkbox data-testId="btnRememberMe"
												onClick={() =>
													this.setRememberMe(!this.state.checkedRememberMe)
												}
												checked={(this.props.navigation?.getParam('orgTab') === "true") ? (this.props.navigation?.getParam('orgTab') === "true") : this.state.checkedRememberMe} />

											<Typography className="typographyRoot">I agree with <span data-testId="handleNavigate" onClick={() => this.handleNavigate()} style={{ color: "black" }}><span style={{ textDecoration: "underline" }}>Terms and Conditions </span>and <span style={{ textDecoration: "underline" }}>Privacy Policy </span></span> </Typography>

										</Box>
										<Box className="buttonroot">
											
												<Button data-testId="handleOpenConfirmPassword" onClick={() => {

													this.handleConfirmEmail();

												}} >Confirm email address</Button>
											
										</Box>
										<Box className={!this.state.showResendEmail ? "resend-box center-align-button" :"resend1-box center-align-button" } >
											{this.state.showResendEmail&& <Typography data-testId="handleOpen" onClick={() => this.handleConfirmEmail()} className="signupAccountStyle1">Resend email </Typography>}
											<Typography className="signupAccountStyle">Have an account? <Box style={{ color: "white"  }}>/</Box><Link className="loginlink" to="/EmailAccountLoginBlock">Login</Link></Typography>
										</Box>
									</>
								)}
							</Box>
						</Box>
					</Box>
				</ThemeProvider>
			</>
			// Customizable Area End
		);
	}

}
// Customizable Area Start
// Customizable Area End
